import React, { useEffect, useState } from 'react';
import Otp from '../components/otp/otp';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { verifyOtp } from './services/authentication';
import Rotate from '../assets/img/rotate-solid.svg';

export default function Validation({
  email,
  formFields,
  setFormFields,
  otpState,
  setOtpState,
  sendOtpAction,
  setResendAction,
  setShowResendMess,
  showResendMess,
}) {
  const history = useHistory();
  const [prefilledOtp, setPrefilledOtp] = useState(false);

  const [otp, setOtp] = useState(sessionStorage.otp || '');
  const [isVerified, setIsVerified] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);

  useEffect(() => {
    const otp = sessionStorage.otp;
    if (otp) {
      setPrefilledOtp(true);
      setOtp(otp);
    }
  }, []);
  // show resend button after 30 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowResend(true);
    }, 30000);
    return () => clearTimeout(timer);
  }, [otpState]);

  // countdown timer
  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 1) {
        setTimeLeft(timeLeft - 1);
      } else setShowResend(true);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  const verifyOtpAction = async () => {
    setShowResend(false);
    setShowResendMess(false);
    const institution = sessionStorage.institution;
    if (institution) {
      const tournament = sessionStorage.redirectToURL.split('/')[2].split('?')[0];
      return await verifyOtpMutation.mutateAsync({ email, otp, institution, tournament });
    } else return await verifyOtpMutation.mutateAsync({ email, otp });
  };

  const verifyOtpMutation = useMutation((data) => verifyOtp(data), {
    onSuccess: (res) => {
      // console.log(res);
      if (res.message === 'success') {
        setFormFields({ ...formFields, otp });
        setIsVerified(true);
      } else {
        setOtpState('invalidOTP');
      }
    },
    onError: async (err) => {
      console.error('error -', err);
    },
  });

  useEffect(() => {
    if (isVerified) {
      history.push('/signup/personal-details');
    }
  }, [isVerified]);

  useEffect(() => {
    if (formFields.role === '') {
      history.push('/signup/');
    }
  }, []);
  return (
    <>
      {!prefilledOtp && (
        <>
          <div className="pt-3 text-gray-700 font-roboto info">
            Kindly Enter The OTP Received In Your Email Inbox.
          </div>
          <div className={'pt-2 pb-5'}>
            {email.toLowerCase()} :{' '}
            <a
              className={'cursor-pointer'}
              onClick={() => {
                setFormFields({ ...formFields, iAgree: false, password: '', submit: false });
                setOtpState('');
                history.push('/signup/edit-email');
              }}
            >
              Edit
            </a>
          </div>
        </>
      )}
      <Otp code={otp} setCode={setOtp} prefilledOtp={prefilledOtp} />
      {!prefilledOtp ? (
        <>
          {!showResend ? (
            <div className="mt-5 flex justify-between mb-5 w-full">
              <div className="text-gray-700 font-roboto info">
                Resend OTP in <span className="text-red-500 font-bold">{timeLeft}</span> seconds
              </div>
            </div>
          ) : (
            <div className="mt-5 flex justify-between mb-5 w-full">
              <div className="text-gray-700 font-roboto info">
                <a
                  onClick={() => {
                    setResendAction(true);
                    setShowResend(false);
                    setShowResendMess(true);
                    setTimeLeft(30);
                    sendOtpAction(email);
                  }}
                  className="cursor-pointer"
                >
                  Resend Code
                </a>
              </div>
            </div>
          )}
          <div className={`${otpState === 'success' ? 'text-green-500' : 'text-red-500'} my-3`}>
            {otpState === 'success' ? (
              showResendMess ? (
                'OTP resent Successfully. Please check your email for the OTP.'
              ) : (
                'OTP Sent Successfully. Please check your email for the OTP.'
              )
            ) : otpState === 'exists' ? (
              <span>
                User Already Exists! <Link to="/signup/">go Back</Link>
              </span>
            ) : otpState === 'error' ? (
              <span>
                Error Occured! <Link to="/signup/">go Back</Link>
              </span>
            ) : otpState === 'invalidOTP' ? (
              'Invalid OTP'
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <div className="mb-6"></div>
      )}
      <div className="flex justify-between w-full">
        <button
          className="flex justify-center items-center reg-btn-dark w-full my-2 rounded-lg px-10 py-2 text-white"
          type="button"
          onClick={() => verifyOtpAction()}
        >
          Submit and Verify
          {verifyOtpMutation.isLoading ? (
            <img src={Rotate} className="ml-2 w-4 h-4 color-white animate-spin" />
          ) : (
            ''
          )}
        </button>
      </div>
    </>
  );
}
